body {
  font-family: "Comic Sans MS", "Comic Sans", "Noteworthy";
background: lightgreen;
}

.ryan-head {
  transition: .2s;
  cursor: pointer;
}

.ryan-head:hover {
  opacity: .8;
  transform: scale(1.1);
}